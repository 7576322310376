// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// routes
import Router from 'src/routes/sections';
import { SWRConfig } from 'swr';
import axios from 'src/utils/axios';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
import useClarity from './utils/useClarity';

// ----------------------------------------------------------------------

export default function App() {

  useClarity("lpxojtkl2k");

  const charAt = `

  ░░     ░░  ░░░░░░░░
  ▒▒     ▒▒  ▒▒    ▒▒
  ▒▒     ▒▒  ▒▒▒▒▒▒▒▒   
  ▓▓     ▓▓  ▓▓
  █████████  ██

  `;

  const fetcher = (url: string) => axios.get(url).then(res => res.data);

  console.info(`%c${charAt}`, 'color: #5BE49B');

  useScrollToTop();

  return (
    <SWRConfig
      value={{
        fetcher, // Your global fetcher function
        revalidateOnFocus: false, // Disable re-fetching when window/tab is focused
        revalidateOnReconnect: false, // Disable re-fetching when reconnecting to the network
        refreshInterval: 0, // Set polling interval; set to 0 to disable polling
      }}
    >
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'blue', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthConsumer>
                    <Router />
                  </AuthConsumer>
                </SnackbarProvider>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </AuthProvider>
    </SWRConfig>
  );
}
