import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'src/utils/axios';
import useSWR from 'swr';
import Label from 'src/components/label';
import { LoadingScreen } from 'src/components/loading-screen';
import {
    Container,
    Grid,
    Stack,
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    Modal,
    FormControlLabel,
    Checkbox,
    Fade,
    Collapse,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    Tooltip
} from '@mui/material';
import { useSettingsContext } from 'src/components/settings';
import { MotionContainer, varBounce } from 'src/components/animate';
import { paths } from 'src/routes/paths';
import CreatedInvoiceDetailsToolbar from 'src/sections/invoice/created-invoice-details-toolbar';
import { useCallback, useEffect, useState } from 'react';
import CompactLayout from 'src/layouts/compact/layout';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { fDate, fDateTime } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import Iconify from 'src/components/iconify';

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const fetcher = (url: string) => axios.get(url).then((res) => res.data);

function PublicCheckoutPage() {
    const settings = useSettingsContext();
    const { token } = useParams<{ token: string }>();
    const [status, setStatus] = useState('');
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [showPaymentMethods, setShowPaymentMethods] = useState(false);
    const [isLoadingSend2fa, setIsLoadingSend2fa] = useState(false);
    const [isLoadingVerify2fa, setIsLoadingVerify2fa] = useState(false);

    const { data: invoiceData, error: invoiceDataError } = useSWR(
        `${apiUrl}/invoice/get_public_invoice/${token}`,
        fetcher
    );

    const handleChangeStatus = useCallback((newValue: string) => {
        setStatus(newValue);
    }, []);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<'ach' | 'credit' | null>(null);
    const [acknowledgedFee, setAcknowledgedFee] = useState(false);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);

    const handleSelectPaymentMethod = (method: 'ach' | 'credit') => {
        setSelectedPaymentMethod(method);
        setAcknowledgedFee(false); // reset fee acknowledgment when switching methods
    };

    const handleDownload = (url: string | URL | undefined) => {
        window.open(url, '_blank');
    };

    const handlePayNow = () => {
        if (selectedPaymentMethod === 'ach') {
            window.location.href = invoiceData.stripe_payment_link_url;
        } else if (selectedPaymentMethod === 'credit') {
            window.location.href = invoiceData.cc_stripe_payment_link_url;
        }
    };

    const [timestamp, setTimestamp] = useState(Date.now());
    useEffect(() => {
        setTimestamp(Date.now());
    }, []);

    // -----------------------------
    // State for the "View Invoices" feature
    // -----------------------------
    const [openViewInvoicesModal, setOpenViewInvoicesModal] = useState(false);
    const [step, setStep] = useState<'enter_email' | 'enter_code' | 'done'>('enter_email');
    const [email, setEmail] = useState('');
    const [twoFaCode, setTwoFaCode] = useState('');
    const [invoicesList, setInvoicesList] = useState<any[]>([]);
    const [openExpanded, setOpenExpanded] = useState(false);

    const handleOpenExpanded = () => setOpenExpanded(true);
    const handleCloseExpanded = () => setOpenExpanded(false);

    const handleOpenViewInvoices = () => {
        setOpenViewInvoicesModal(true);
        setStep('enter_email');
        setEmail('');
        setTwoFaCode('');
    };

    const handleCloseViewInvoices = () => {
        setOpenViewInvoicesModal(false);
    };

    // Example call to backend to request sending a 2FA code to the provided email
    const handleSend2fa = async () => {
        setIsLoadingSend2fa(true);
        try {
            // Adjust this call to match your actual endpoint & payload
            await axios.post(`${apiUrl}/sendgrid/send_2fa_code`, { email, upsteer_id: invoiceData.orderTo.upsteer_id });
            // If successful, move to the next step
            enqueueSnackbar('Authentication code sent successfully.', { variant: 'success' });
            setStep('enter_code');
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Failed to send authentication code, please try again.', { variant: 'error' });
        }
        setIsLoadingSend2fa(false);
    };

    // Example call to backend to verify 2FA code
    const handleVerify2fa = async () => {
        setIsLoadingVerify2fa(true);
        try {
            const upsteer_id = invoiceData.orderTo.upsteer_id;
            // Adjust this call to match your actual endpoint & payload
            await axios.post(`${apiUrl}/sendgrid/verify_2fa_code`, { two_factor_code: +twoFaCode, upsteer_id });

            // // If successful, fetch the user's invoices
            const response = await axios.get(`${apiUrl}/invoice/get_table_created_invoice_public/${upsteer_id}`);
            setInvoicesList(response.data);
            enqueueSnackbar('Authentication code verified successfully.', { variant: 'success' });



            // Switch step to done (or you could close the modal, etc.)
            setStep('done');
            // We’ll close the modal here, if you prefer to keep it open, remove this line:
            setOpenViewInvoicesModal(false);
            setOpenExpanded(true);
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Failed to verify authentication code, please try again.', { variant: 'error' });
        }
        setIsLoadingVerify2fa(false);
    };

    if (!invoiceData) return <LoadingScreen />;
    if (invoiceDataError) return <div>Error loading data</div>;

    // Compute final balance (adding convenience fee if needed)
    const baseBalance = invoiceData.entries?.[0]?.balance || 0;
    const shouldApplyFee =
        selectedPaymentMethod === 'credit' &&
        !invoiceData.is_customer_fee_grace_period &&
        invoiceData.is_customer_fees;
    const convenienceFee = shouldApplyFee ? invoiceData.customer_fees_amount || 0 : 0;
    const totalBalance = baseBalance + convenienceFee;

    return (
        <>
            <Helmet>
                <title>Invoice Checkout</title>
            </Helmet>

            <CompactLayout email={invoiceData.account_email}>
                <Stack spacing={3} sx={{ p: { xs: 2, md: 3 } }}>
                    {/* Top toolbar with invoice number and "Download" button */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ mb: { xs: 3, md: 5 } }}
                    >
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography variant="h4">
                                Invoice #{invoiceData.invoice_document_number}
                            </Typography>
                            <Label
                                variant="soft"
                                color={
                                    (invoiceData.payment_status === 'open' && 'warning') ||
                                    (invoiceData.payment_status === 'overdue' && 'error') ||
                                    (invoiceData.payment_status === 'failed' && 'error') ||
                                    (invoiceData.payment_status === 'paid' && 'success') ||
                                    'default'
                                }
                            >
                                {invoiceData.payment_status || 'None'}
                            </Label>
                        </Stack>
                    </Stack>

                    <Grid container spacing={3}>
                        {/* Right Column: Payment actions (displayed on top in mobile view) */}
                        <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{
                                order: { xs: 1, md: 2 }, // mobile: top, desktop: right
                            }}
                        >
                            <Stack spacing={3}>
                                {/* 1) Balance Summary Card */}
                                <Card sx={{ maxWidth: { xs: '100%', md: 400 } }}>
                                    <CardContent>
                                        <Stack spacing={3}>
                                            {/* Company Logo and Name */}
                                            {invoiceData.logo_url && (
                                                <Stack spacing={1}>
                                                    <Box
                                                        component="img"
                                                        src={invoiceData.logo_url}
                                                        alt={invoiceData.company_name}
                                                        sx={{
                                                            height: 'auto',
                                                            width: 'auto',
                                                            maxHeight: 100,
                                                            maxWidth: 200,
                                                            objectFit: 'contain',
                                                            mb: 1,
                                                            alignSelf: 'start',
                                                        }}
                                                    />
                                                    <Typography variant="h6" align="left">
                                                        {invoiceData.company_name}
                                                    </Typography>
                                                </Stack>
                                            )}

                                            {/* Payment Section */}
                                            {invoiceData.payment_status !== 'paid' &&
                                                (invoiceData.allow_cc_payment || invoiceData.allow_ach_payment) && (
                                                    <Stack spacing={2}>
                                                        <Fade in={!showPaymentMethods}>
                                                            <Box sx={{ display: showPaymentMethods ? 'none' : 'block' }}>
                                                                <Button
                                                                    fullWidth
                                                                    variant="contained"
                                                                    onClick={() => setShowPaymentMethods(true)}
                                                                >
                                                                    Pay Now
                                                                </Button>
                                                            </Box>
                                                        </Fade>
                                                        <Collapse in={showPaymentMethods} timeout={300}>
                                                            <Box sx={{ py: 1 }}>
                                                                <Fade in={showPaymentMethods} timeout={600}>
                                                                    <Stack spacing={2}>
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.secondary"
                                                                            align="center"
                                                                        >
                                                                            Select payment method
                                                                        </Typography>
                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={2}
                                                                            sx={{ justifyContent: 'center' }}
                                                                        >
                                                                            {invoiceData.allow_ach_payment && (
                                                                                <Button
                                                                                    fullWidth
                                                                                    variant={
                                                                                        selectedPaymentMethod === 'ach'
                                                                                            ? 'contained'
                                                                                            : 'outlined'
                                                                                    }
                                                                                    onClick={() => handleSelectPaymentMethod('ach')}
                                                                                >
                                                                                    ACH
                                                                                </Button>
                                                                            )}
                                                                            {invoiceData.allow_cc_payment && (
                                                                                <Button
                                                                                    fullWidth
                                                                                    variant={
                                                                                        selectedPaymentMethod === 'credit'
                                                                                            ? 'contained'
                                                                                            : 'outlined'
                                                                                    }
                                                                                    onClick={() => handleSelectPaymentMethod('credit')}
                                                                                >
                                                                                    Credit Card
                                                                                </Button>
                                                                            )}
                                                                        </Stack>

                                                                        {selectedPaymentMethod === 'credit' &&
                                                                            invoiceData.is_customer_fees && !invoiceData.is_customer_fee_grace_period && (
                                                                                <FormControlLabel
                                                                                    sx={{
                                                                                        pt: 1,
                                                                                        '.MuiFormControlLabel-label': { textAlign: 'left' },
                                                                                    }}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={acknowledgedFee}
                                                                                            onChange={(e) => setAcknowledgedFee(e.target.checked)}
                                                                                        />
                                                                                    }
                                                                                    label={`By selecting credit card as your payment method, you acknowledge and agree to a convenience fee of ${invoiceData.customer_fees_value}% applied to your invoice total.`}
                                                                                />
                                                                            )}

                                                                        {(selectedPaymentMethod === 'ach' ||
                                                                            (selectedPaymentMethod === 'credit' &&
                                                                                (acknowledgedFee || !invoiceData.is_customer_fees || invoiceData.is_customer_fee_grace_period))) && (
                                                                                <Button variant="contained" onClick={handlePayNow} fullWidth>
                                                                                    Pay Now
                                                                                </Button>
                                                                            )}
                                                                    </Stack>
                                                                </Fade>
                                                            </Box>
                                                        </Collapse>
                                                    </Stack>
                                                )}

                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                alignItems="center"
                                                sx={{ marginTop: -3 }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="inherit"
                                                    sx={{ flex: 1 }}
                                                    onClick={() => handleDownload(invoiceData.pdf_url)}
                                                >
                                                    Download Invoice
                                                </Button>
                                            </Stack>

                                            {/* Invoice Details */}
                                            <Stack spacing={2}>
                                                <Stack direction="row" justifyContent="space-between">
                                                    <Typography color="text.secondary">Invoice</Typography>
                                                    <Typography>{invoiceData.invoice_document_number}</Typography>
                                                </Stack>
                                                <Stack direction="row" justifyContent="space-between">
                                                    <Typography color="text.secondary">Due date</Typography>
                                                    <Typography>{fDate(invoiceData.invoice_due_date)}</Typography>
                                                </Stack>
                                            </Stack>

                                            {/* List invoice entries */}
                                            <Stack spacing={2}>
                                                {invoiceData.entries.map((entry: any, index: any) => {
                                                    let label = '';
                                                    const date = entry.txn_date;
                                                    const amount = entry.total_amount;
                                                    const reference_number = entry.reference_number;

                                                    switch (entry.object) {
                                                        case 'total_amount':
                                                            label = 'Opening Balance';
                                                            break;
                                                        case 'payment':
                                                            label = 'Payment';
                                                            break;
                                                        case 'credit_note':
                                                            label = `Credit Note (${reference_number})`;
                                                            break;
                                                        case 'refund':
                                                            label = 'Refund';
                                                            break;
                                                        case 'payment_processing':
                                                            label = 'Payment Processing';
                                                            break;
                                                        case 'convenience_fee':
                                                            label = 'Convenience Fee';
                                                            break;
                                                        default:
                                                            label = 'Unknown Transaction';
                                                    }

                                                    return (
                                                        <Stack
                                                            key={index}
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="flex-start"
                                                        >
                                                            <Box>
                                                                <Typography variant="body1" pr={2}>
                                                                    {label}
                                                                </Typography>
                                                                <Stack direction="row" spacing={1}>
                                                                    <Typography variant="caption" color="text.secondary">
                                                                        {fDate(date)}
                                                                    </Typography>
                                                                </Stack>
                                                            </Box>
                                                            <Typography
                                                                variant="body1"
                                                                color={amount < 0 ? 'error.main' : 'text.primary'}
                                                            >
                                                                {fCurrency(amount)}
                                                            </Typography>
                                                        </Stack>
                                                    );
                                                })}

                                                {/* Convenience fee row (if applicable) */}
                                                {shouldApplyFee && (
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="flex-start"
                                                    >
                                                        <Box>
                                                            <Typography variant="body1" pr={2}>
                                                                {`Convenience Fee (${invoiceData.customer_fees_value || 0
                                                                    }%)`}
                                                            </Typography>
                                                            <Stack direction="row" spacing={1}>
                                                                <Typography variant="caption" color="text.secondary">
                                                                    {fDateTime(new Date())}
                                                                </Typography>
                                                            </Stack>
                                                        </Box>
                                                        <Typography variant="body1">
                                                            {fCurrency(invoiceData.customer_fees_amount || 0)}
                                                        </Typography>
                                                    </Stack>
                                                )}
                                            </Stack>

                                            {/* Amount Due */}
                                            <Box sx={{ pt: 2, borderTop: 1, borderColor: 'divider' }}>
                                                <Stack direction="row" justifyContent="space-between">
                                                    <Typography variant="subtitle1">Amount Due</Typography>
                                                    <Typography variant="subtitle1">
                                                        {fCurrency(totalBalance)}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </CardContent>
                                </Card>

                                <Card sx={{ maxWidth: { xs: '100%', md: 400 } }}>
                                    <CardContent>
                                        <Stack spacing={2}>
                                            {/* Button to open the 2FA process or request (original logic) */}
                                            <Button
                                                variant="contained"
                                                onClick={handleOpenViewInvoices}
                                                disabled={invoicesList.length > 0}
                                            >
                                                View All Invoices
                                            </Button>

                                            {/* Once 2FA is successful and invoicesList has data, show the table */}
                                            {invoicesList.length > 0 && (
                                                <Box mt={2}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        mb={1}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        Your Invoices
                                                        {/* Small "expand" icon button to open a larger view */}
                                                        <Tooltip title="Expand" arrow>
                                                            <Iconify
                                                                icon="fluent:expand-up-right-48-filled"
                                                                onClick={handleOpenExpanded}
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    color: 'text.secondary',       // or another default color
                                                                    '&:hover': {
                                                                        color: 'primary.main',       // change color on hover
                                                                    },
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Typography>

                                                    {/* Table with max-height for scroll */}
                                                    <TableContainer
                                                        component={Paper}
                                                        sx={{ maxHeight: 450, overflow: 'auto' }}
                                                    >
                                                        <Table size="small" stickyHeader>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Invoice</TableCell>
                                                                    <TableCell>Status</TableCell>
                                                                    <TableCell>Balance</TableCell>
                                                                    <TableCell>Due Date</TableCell>
                                                                    <TableCell>Action</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {invoicesList.map((inv) => (
                                                                    <TableRow key={inv.id}>
                                                                        <TableCell>{inv.documentNumber}</TableCell>
                                                                        <TableCell>
                                                                            <Label
                                                                                color={
                                                                                    (inv.status === 'open' && 'warning') ||
                                                                                    (inv.status === 'overdue' && 'error') ||
                                                                                    (inv.status === 'paid' && 'success') ||
                                                                                    'default'
                                                                                }
                                                                            >
                                                                                {inv.status}
                                                                            </Label>
                                                                        </TableCell>
                                                                        <TableCell>{fCurrency(inv.balance)}</TableCell>
                                                                        <TableCell>{fDate(inv.dueDate)}</TableCell>
                                                                        <TableCell>
                                                                            <Button
                                                                                variant="outlined"
                                                                                onClick={() =>
                                                                                    window.open(`/invoice/checkout/${inv.id}`, '_blank')
                                                                                }
                                                                            >
                                                                                View
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            )}
                                        </Stack>
                                    </CardContent>

                                    {/* Dialog for expanded view */}
                                    <Dialog
                                        open={openExpanded}
                                        onClose={handleCloseExpanded}
                                        fullWidth
                                        maxWidth="lg"
                                    >
                                        <DialogTitle>All Invoices</DialogTitle>
                                        <DialogContent dividers>
                                            <TableContainer component={Paper}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Invoice</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>Balance</TableCell>
                                                            <TableCell>Due Date</TableCell>
                                                            <TableCell>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {invoicesList.map((inv) => (
                                                            <TableRow key={inv.id}>
                                                                <TableCell>{inv.documentNumber}</TableCell>
                                                                <TableCell>
                                                                    <Label
                                                                        color={
                                                                            (inv.status === 'open' && 'warning') ||
                                                                            (inv.status === 'overdue' && 'error') ||
                                                                            (inv.status === 'paid' && 'success') ||
                                                                            'default'
                                                                        }
                                                                    >
                                                                        {inv.status}
                                                                    </Label>
                                                                </TableCell>
                                                                <TableCell>{fCurrency(inv.balance)}</TableCell>
                                                                <TableCell>{fDate(inv.dueDate)}</TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        variant="outlined"
                                                                        onClick={() =>
                                                                            window.open(`/invoice/checkout/${inv.id}`, '_blank')
                                                                        }
                                                                    >
                                                                        View
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </DialogContent>
                                    </Dialog>
                                </Card>
                            </Stack>
                        </Grid>

                        {/* Left Column: Invoice PDF (displayed below in mobile view) */}
                        <Grid
                            item
                            xs={12}
                            md={8}
                            sx={{
                                display: { xs: 'none', md: 'block' },
                                order: { xs: 2, md: 1 }, // mobile: below payment, desktop: left side
                            }}
                        >
                            {invoiceData.pdf_url && (
                                <Box
                                    sx={{
                                        border: '1px solid #ccc',
                                        borderRadius: 1,
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Box
                                        component="iframe"
                                        title="sendInvoice"
                                        src={`${invoiceData.pdf_url}?t=${timestamp}#toolbar=0&navpanes=0&scrollbar=0`}
                                        sx={{
                                            border: 'none',
                                            width: '100%',
                                            height: { xs: 400, md: 1025 },
                                        }}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Stack>
            </CompactLayout>

            {/* -----------------------------
          View Invoices Modal
      ----------------------------- */}
            <Modal open={openViewInvoicesModal} onClose={handleCloseViewInvoices}>
                <Box
                    sx={{
                        position: 'absolute' as const,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        p: 3,
                        borderRadius: 2,
                        boxShadow: 24,
                    }}
                >
                    {step === 'enter_email' && (
                        <Stack spacing={2}>
                            <Typography variant="h6">Enter your email to receive an authentication code to view all invoices</Typography>
                            <TextField
                                fullWidth
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <LoadingButton variant="contained" onClick={handleSend2fa} loading={isLoadingSend2fa}>
                                Send Code
                            </LoadingButton>
                        </Stack>
                    )}

                    {step === 'enter_code' && (
                        <Stack spacing={2}>
                            <Typography variant="h6">Enter the 6-digit code</Typography>
                            <TextField
                                fullWidth
                                label="Authentication Code"
                                value={twoFaCode}
                                onChange={(e) => setTwoFaCode(e.target.value)}
                            />
                            <LoadingButton variant="contained" onClick={handleVerify2fa} loading={isLoadingVerify2fa}>
                                Verify
                            </LoadingButton>
                        </Stack>
                    )}
                </Box>
            </Modal>
        </>
    );
}

export default PublicCheckoutPage;
