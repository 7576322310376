import { useMemo } from 'react';
import { paths } from 'src/routes/paths';
import axios from 'src/utils/axios';
import useSWR from 'swr';
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify';

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  invoice: icon('ic_invoice'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  banking: icon('ic_banking'),
  external: icon('ic_external'),
  creditNote: icon('ic_file'), // Using the same icon as file for now
  userBold: icon('ic_user_bold'),
};

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export function useNavData() {
  // Fetch user info
  const { data: userInfo, error: userInfoError } = useSWR(
    `${apiUrl}/user/general`,
    fetcher
  );
  const { data: orderSettings, error: orderSettingsError } = useSWR(`${apiUrl}/manual_order/upsteer_order_settings`, fetcher);

  // isLoading is true if no data yet but no error
  const isLoading = !userInfo || !orderSettings;
  const hasError = !!userInfoError || !!orderSettingsError;

  const data = useMemo(() => {
    // If we have no user info or an error, return an empty array
    if (!userInfo || hasError || !orderSettings) {
      return [];
    }

    return [
      // OVERVIEW
      {
        subheader: ' ',
        items: [
          {
            title: 'Dashboard',
            path: paths.dashboard.app,
            icon: ICONS.dashboard,
          },
          // Conditionally add Sales Orders
          ...(orderSettings?.show_sales_orders
            ? [
              {
                title: 'Sales Orders',
                path: paths.dashboard.order_upsteer,
                icon: ICONS.invoice,
              },
            ]
            : []),
          {
            title: 'Invoices',
            path: paths.dashboard.invoice,
            icon: ICONS.file,
          },
          {
            title: 'Credit Notes',
            path: paths.dashboard.creditNotes,
            icon: <Iconify icon="solar:feed-bold-duotone" width={24} />,
          },
          {
            title: 'Product Mapping',
            path: paths.dashboard.product_mapping,
            icon: ICONS.ecommerce,
          },
          {
            title: 'Customers',
            path: paths.dashboard.customer.list,
            icon: <Iconify icon="ph:users-duotone" width={24} />,
          },
          {
            title: 'Analytics',
            path: paths.dashboard.analytics,
            icon: ICONS.analytics,
          },
          {
            title: 'Payments',
            path: paths.dashboard.payments,
            icon: ICONS.banking,
          },
          {
            title: 'Integrations',
            path: paths.dashboard.connection,
            icon: ICONS.external,
          },
        ],
      },

      // MANAGEMENT
      {
        subheader: 'management',
        items: [
          {
            title: 'Account',
            path: paths.dashboard.account,
            icon: ICONS.user,
          },
          {
            title: 'Help Center',
            path: 'https://intercom.help/upsteer/en', // Replace with the desired URL
            icon: <Iconify icon="solar:question-square-bold-duotone" width={24} />,
          },
        ],
      },
    ];
  }, [userInfo, hasError, orderSettings]);

  return data;
}
