// src/routes/public.ts
import { lazy, Suspense } from 'react';
import { SplashScreen } from 'src/components/loading-screen';
import PublicCheckoutPage from 'src/pages/PublicCheckoutPage';

// If you want to wrap with Suspense for a loading fallback:
export const publicRoutes = [
    {
        path: 'invoice',
        children: [
            {
                path: 'checkout/:token',
                element: (
                    <Suspense fallback={<SplashScreen />}>
                        <PublicCheckoutPage />
                    </Suspense>
                ),
            },
        ],
    },
];
